import { FC } from "react";

interface IconHeartStrokeProps {
  className?: string;
}

const IconHeartStroke: FC<IconHeartStrokeProps> = ({ className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M8.96173 19.9109L9.42605 19.3219L8.96173 19.9109ZM12 6.50063L11.4596 7.02073C11.601 7.16763 11.7961 7.25063 12 7.25063C12.2039 7.25063 12.399 7.16763 12.5404 7.02073L12 6.50063ZM15.0383 19.9109L15.5026 20.4999L15.0383 19.9109ZM9.42605 19.3219C7.91039 18.1271 6.25307 16.9603 4.93829 15.4798C3.64922 14.0282 2.75 12.3345 2.75 10.1371H1.25C1.25 12.8026 2.3605 14.836 3.81672 16.4758C5.24722 18.0866 7.07077 19.3752 8.49742 20.4999L9.42605 19.3219ZM2.75 10.1371C2.75 7.98623 3.96537 6.18252 5.62436 5.42419C7.23607 4.68748 9.40166 4.88258 11.4596 7.02073L12.5404 5.98053C10.0985 3.44352 7.26409 3.02539 5.00076 4.05996C2.78471 5.07292 1.25 7.42503 1.25 10.1371H2.75ZM8.49742 20.4999C9.00965 20.9037 9.55954 21.3343 10.1168 21.6599C10.6739 21.9854 11.3096 22.25 12 22.25V20.75C11.6904 20.75 11.3261 20.6293 10.8736 20.3648C10.4213 20.1005 9.95208 19.7366 9.42605 19.3219L8.49742 20.4999ZM15.5026 20.4999C16.9292 19.3752 18.7528 18.0866 20.1833 16.4758C21.6395 14.836 22.75 12.8026 22.75 10.1371H21.25C21.25 12.3345 20.3508 14.0282 19.0617 15.4798C17.7469 16.9603 16.0896 18.1271 14.574 19.3219L15.5026 20.4999ZM22.75 10.1371C22.75 7.42503 21.2153 5.07292 18.9992 4.05996C16.7359 3.02539 13.9015 3.44352 11.4596 5.98053L12.5404 7.02073C14.5983 4.88258 16.7639 4.68748 18.3756 5.42419C20.0346 6.18252 21.25 7.98623 21.25 10.1371H22.75ZM14.574 19.3219C14.0479 19.7366 13.5787 20.1005 13.1264 20.3648C12.6739 20.6293 12.3096 20.75 12 20.75V22.25C12.6904 22.25 13.3261 21.9854 13.8832 21.6599C14.4405 21.3343 14.9903 20.9037 15.5026 20.4999L14.574 19.3219Z"
        fill="#4F5E71"
      />
    </svg>
  );
};

export default IconHeartStroke;
