import { StaticImageData } from "next/image";

import OptimizedImage from "../common/images/OptimizedImage";
import { formatImageUrl } from "@/lib/utils";
import { DEFAULT_PLACEHOLDER } from "@/utils/constants";

interface CarCardImageProps {
  mediaUrl?: string | StaticImageData | null;
  blurPlaceholder: string;
  makeModel: string;
  index: number;
}

export function CarCardImage({ mediaUrl, makeModel, blurPlaceholder, index }: CarCardImageProps) {
  const imageUrl = typeof mediaUrl === "string" ? `${formatImageUrl(mediaUrl)}` : mediaUrl;

  return (
    <div className="relative h-60 overflow-hidden rounded-3xl">
      <OptimizedImage
        fill
        fetchPriority={index === 0 ? "high" : "low"}
        priority={ index === 0 }
        quality={75}
        sizes="(max-width: 768px) 300px, (max-width: 1200px) 400px, 500px"
        maxWidth={500}
        imageLayout="custom"
        src={imageUrl ?? DEFAULT_PLACEHOLDER}
        alt={`${makeModel} image`}
        className={`rounded-3xl object-cover object-bottom transition-transform duration-700`}
        loading={index === 0 ? "eager" : "lazy"}
        placeholder="blur"
        blurDataURL={blurPlaceholder}
      />
    </div>
  );
}
