import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import React from "react";

import { cn } from "@/lib/utils";
import "./GenericTooltip.css";

interface GenericTooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  delayDuration?: number;
  side?: "top" | "right" | "bottom" | "left";
  sideOffset?: number;
  align?: "start" | "center" | "end";
  contentClassName?: string;
  arrowClassName?: string;
}

export const GenericTooltip = ({
  children,
  content,
  delayDuration = 200,
  side = "top",
  sideOffset = 5,
  align = "center",
  contentClassName = "",
  arrowClassName = "",
}: GenericTooltipProps) => {
  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            className={cn("tooltip-content", contentClassName)}
            sideOffset={sideOffset}
            side={side}
            align={align}
          >
            {content}
            <TooltipPrimitive.Arrow className={cn("tooltip-arrow", arrowClassName)} />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
