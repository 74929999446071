import { hasValue, Vehicle } from "@alba-cars/common-modules";

/**
 * Truncates text to a maximum length
 */
export const truncateText = (text: string, maxLength: number = 100): string => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};

/**
 * Returns the first option from an array or the value if it's not an array
 */
export const getValueOrFirstOption = <T>(value: T | T[]): T => {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};

/**
 * Checks if an object has any defined values (not null or undefined or empty)
 */
export const hasDefinedValues = (obj?: Record<string, unknown>): boolean => {
  const cleanedObject = obj ? Object.fromEntries(Object.entries(obj).filter(([_, value]) => hasValue(value))) : {};
  return (
    hasValue(cleanedObject) &&
    Object.keys(cleanedObject).length > 0 &&
    Object.values(cleanedObject).some((value) => hasValue(value))
  );
};

export const getS3Url = (fileName: string): string => {
  return `${process.env.NEXT_PUBLIC_AWS_S3_URL}/${fileName}`;
};

export const getCarNameTitle = (
  vehicle: Vehicle,
  { showTrim = false, showYear = false }: { showTrim?: boolean; showYear?: boolean },
): string => {
  if (vehicle.title) {
    return vehicle.title;
  } else {
    let result = `${vehicle.make.name} ${vehicle.model.name}`;

    if (showTrim && vehicle.trim) {
      result += ` ${vehicle.trim}`;
    }

    if (showYear && vehicle.year) {
      result += ` ${vehicle.year}`;
    }

    return result.trim();
  }
};
