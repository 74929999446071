import { FC } from "react";

interface IconArrowRightProps {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
}

const IconArrowRight: FC<IconArrowRightProps> = ({ fill, width, height, className }) => {
  return (
    <svg
      width={width ?? "14"}
      height={height ?? "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M5.17604 12.8113L10.0906 7.91128C10.149 7.85295 10.1904 7.78975 10.2149 7.7217C10.2394 7.65365 10.2514 7.58073 10.251 7.50296C10.251 7.42519 10.239 7.35225 10.2149 7.28421C10.1908 7.21617 10.1493 7.15296 10.0906 7.09465L5.17604 2.18002C5.03993 2.04394 4.86979 1.9759 4.66562 1.9759C4.46146 1.9759 4.28646 2.04877 4.14063 2.19465C3.99479 2.34046 3.92188 2.51056 3.92188 2.70502C3.92188 2.89948 3.99479 3.06965 4.14063 3.21546L8.4281 7.50296L4.14063 11.7904C4.00451 11.9266 3.93646 12.0944 3.93646 12.2939C3.93646 12.4934 4.00938 12.6658 4.15521 12.8113C4.30104 12.9571 4.47118 13.03 4.66562 13.03C4.86006 13.03 5.03021 12.9571 5.17604 12.8113Z"
        fill={fill ? fill : "black"}
      />
    </svg>
  );
};

export default IconArrowRight;
