"use client";

import React, { ReactNode } from "react";
import { Navigation, Pagination, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { VirtualOptions } from "swiper/types";

import { cn } from "@/lib/utils";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface SliderProps {
  children: ReactNode;
  spaceBetween?: number;
  slidesPerView?: number;
  breakpoints?: Record<number, { slidesPerView: number; spaceBetween?: number }>;
  navigationEnabled?: boolean;
  paginationEnabled?: boolean;
  className?: string;
  initialSlide?: number;
  navigation?: {
    nextEl: string;
    prevEl: string;
  };
  virtual?: boolean | VirtualOptions<any>;
  loop?: boolean;
}

const Slider: React.FC<SliderProps> = ({
  children,
  spaceBetween = 30,
  slidesPerView = 1,
  breakpoints = {},
  navigationEnabled = true,
  paginationEnabled = true,
  initialSlide = 0,
  className = "",
  navigation,
  virtual,
  loop = false,
}) => {
  return (
    <Swiper
      modules={virtual ? [Navigation, Pagination, Virtual] : [Navigation, Pagination]}
      virtual={virtual}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      navigation={navigationEnabled ? navigation : false}
      pagination={paginationEnabled ? { clickable: true } : false}
      breakpoints={breakpoints}
      grabCursor={true}
      simulateTouch={true}
      preventInteractionOnTransition={true}
      resistance={false}
      resistanceRatio={0}
      watchSlidesProgress={true}
      touchRatio={1}
      loop={loop}
      initialSlide={initialSlide}
      touchStartPreventDefault={true}
      className={cn("w-full", className)}
    >
      {React.Children.map(children, (child: any, index) => (
        <SwiperSlide key={`slider-${child?.key ?? ""}-${index}`} virtualIndex={index}>
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
