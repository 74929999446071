"use client";

import { useState } from "react";

import IconHeart from "@/components/Icons/IconHeart";
import IconHeartStroke from "@/components/Icons/IconHeartStroke";
import { useFavorites } from "@/contexts/FavoritesContext";
import { GenericTooltip } from "../tooltip/GenericTooltip";

interface CarFavoriteButtonProps {
  carId: string;
  className?: string;
  withTooltip?: boolean;
  unfavoriteCallback?: () => void;
}

interface HeartParticle {
  id: number;
  style: {
    left: string;
    top: string;
    opacity: number;
    delay: string;
  };
}

const HEART_POSITIONS = [
  { x: -15, y: -10, delay: "0s" },
  { x: 5, y: -30, delay: "0.1s" },
  { x: 15, y: -10, delay: "0.2s" },
];

export const CarFavoriteButton: React.FC<CarFavoriteButtonProps> = ({
  carId,
  className,
  withTooltip = false,
  unfavoriteCallback,
}) => {
  const { isFavorite, toggleFavorite } = useFavorites();
  const [isLoading, setIsLoading] = useState(false);
  const [particles, setParticles] = useState<HeartParticle[]>([]);

  const isLiked = isFavorite(carId);

  const createParticles = () => {
    const newParticles: HeartParticle[] = HEART_POSITIONS.map((position, index) => ({
      id: Date.now() + index,
      style: {
        left: `calc(50% + ${position.x}px)`,
        top: `calc(50% + ${position.y}px)`,
        opacity: 1,
        delay: position.delay,
      },
    }));

    setParticles(newParticles);

    setTimeout(() => {
      setParticles([]);
    }, 1200);
  };

  const handleToggle = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isLoading) return;

    setIsLoading(true);
    try {
      await toggleFavorite(carId);
      if (!isLiked) {
        createParticles();
      }
      if (isLiked && unfavoriteCallback) {
        unfavoriteCallback();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const buttonContent = (
    <div
      className={
        className ??
        "absolute right-3 top-3 z-30 cursor-pointer rounded-full bg-white p-2 transition-all duration-300 hover:bg-secondary-light"
      }
      role="button"
      aria-label="Favorite Car"
      aria-pressed={isLiked}
      onClick={handleToggle}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleToggle(e as unknown as React.MouseEvent);
        }
      }}
    >
      <div className="relative">
        <IconHeartStroke
          className={`absolute transition-opacity duration-300 ${isLiked ? "opacity-0" : "opacity-100"}`}
        />
        <IconHeart
          fill="#ff3347"
          className={`transition-opacity duration-300 ${isLiked ? "opacity-100" : "opacity-0"}`}
        />
        {particles.map((particle) => (
          <div
            key={particle.id}
            className="heart-particle absolute z-50 h-3 w-3"
            style={{
              ...particle.style,
              transform: `translate(-50%, -50%)`,
              animationDelay: particle.style.delay,
            }}
          >
            <IconHeart fill="#ff3347" className="h-full w-full" />
          </div>
        ))}
      </div>
    </div>
  );

  if (withTooltip) {
    return (
      <GenericTooltip content={isLiked ? "Remove from favorites" : "Add to favorites"}>{buttonContent}</GenericTooltip>
    );
  }

  return buttonContent;
};
